/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Hace mucho que no escribo nada, todo culpa de tanta fiesta, pero ahora que estoy de relax me pondré a hacer varios proyectos que tenía en mente. Voy a actualizar el blogroll que lo tengo un poco vacio, así que añado primero un gran foro de modding, ya sabeis el arte de modificar tu PC y quien no lo sepa mas motivos para entrar,  ", React.createElement(_components.a, {
    href: "http://www.hardcore-modding.com/",
    title: "Hardcore Modding"
  }, "Hardcore Modding"), ", el segundo es otro foro también muy bueno de carputer, esto ya es menos conocido consiste en meter un PC en el coche, ", React.createElement(_components.a, {
    href: "http://www.solocarputer.com",
    title: "Solo Carputer"
  }, "Solo Carputer"), ". Lo que mas me gusta de estos dos foro es su sección de electrónica, lógico ;-) , en esta sección de Hardcore Modding podeis encontrar la mejor guia de LEDs que he visto nunca a parte de otros muchisimos posts muy interesantes."), "\n", React.createElement(_components.p, null, "Un saludo"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
